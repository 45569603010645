import type { ChapterSummaryData } from '../types/widgets/composites/ChapterSummaryTypes'

export function fakeChapterSummary(): ChapterSummaryData {
  return {
    locked: false,
    stick_to_next: false,
    type: 'chapters_summary',
    display_hint: 'default',
    uuid: '',
  }
}
